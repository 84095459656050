import moment from 'moment';
import 'moment/locale/es';
import { renderHelpScout } from '@/utils/utils';
import { localStorageService } from '@/utils/local_storage';

/**
 * Language is a class that holds methods to internationalize
 */
class Language {
  constructor(lang) {
    try {
      this.currentLanguage = lang?.split('-')[0] || 'en';
    } catch (ex) {
      this.currentLanguage = 'en';
    }
    // moment locale is set globally so update it to the initial language value
    moment.locale(this.currentLanguage);
  }

  getCurrentLanguage = () => {
    return this.currentLanguage || 'en';
  };

  setCurrentLanguage = (lang) => {
    this.currentLanguage = lang?.split('-')[0];
    // moment locale is set globally so update it whenever the langauge is changed
    localStorageService.set('locale', this.currentLanguage);
    moment.locale(this.currentLanguage);
    // TODO: check if pro; if not, re-render helpscout in new lang
    renderHelpScout(this.currentLanguage);
  };

  /**
   *
   * @param {array|string} text
   * @param {string} selectedLangCode
   * @returns {string} the desired string translated to the correct locale
   */
  getLanguageString = (text, use_locale = null) => {
    //check if text is an array, if so, find the key that represents the selected language code (i.e. "en-US").
    //otherwise, just use the text string.
    let langString = null;
    let locale = use_locale || this.getCurrentLanguage();
    if (Array.isArray(text)) {
      text.forEach((lang) => {
        if (lang.langCode.includes(locale)) {
          langString = lang.text;
        }
      });

      //if it's just a regular string (no language array), just use the string.
    } else {
      langString = text;
    }
    return langString;
  };

  /**
   * Returns a string from the UI Translations, that correspond to the language the user selected
   * If a third parameter, a dictionary is passed, it will interpolate those values in the template
   *
   * @param {string} text_code
   * @param {string} selectedLangCode
   * @param {object} vars the values used to make the substitutions
   * @returns {string} the desired string translated to the correct locale
   */
  t(translations, text, vars = null) {
    let template;

    template = translations[text] || text || '';

    if (!vars) {
      return template;
    }
    return this.fill_template(template, vars);
  }

  interpolateString(template, vars) {
    return template.replace(/\${(.*?)}/g, (match, p1) => {
      const keys = p1.split('.'); // Split nested keys
      let value = vars;

      // Traverse the object using the nested keys
      for (const key of keys) {
        if (value && typeof value === 'object' && key in value) {
          value = value[key];
        } else {
          // Return the original placeholder if the key is not found
          return match;
        }
      }

      return value;
    });
  }

  fill_template(template, vars) {
    let interpolated;
    try {
      interpolated = this.interpolateString(template, vars);
    } catch {
      console.warn('Failed translating a dynamic string');
      interpolated = template;
    }

    return interpolated;
  }
}

const languageInstance = new Language(localStorageService.get('locale'));

export const withTranslations = (translationArray) => {
  return (text, vars = null) => {
    return languageInstance.t(translationArray[languageInstance.getCurrentLanguage()], text, vars);
  };
};

export default languageInstance;

import cloneDeep from 'lodash/cloneDeep';

const reportHelpers = {
  filterReportData: (filters, reportKey, data) => {
    switch (reportKey) {
      case 'referral-summary':
        if (!filters.incomingOrOutgoing || !filters.inOrOutOfNetwork || !data?.[filters.incomingOrOutgoing]?.[filters.inOrOutOfNetwork]) {
          return {
            selectedChartData: cloneDeep(data),
            selectedStats: false
          };
        } else {
          return {
            selectedChartData: cloneDeep(data[filters.incomingOrOutgoing][filters.inOrOutOfNetwork].chart_data),
            selectedStats: data[filters.incomingOrOutgoing][filters.inOrOutOfNetwork].stats
          };
        }
        break;
      // TODO: add logic for subsequent reports when they migrate to React
    }
    return data;
  },
  balanceChartData: (dataRow) => {
    let balance = 0;

    dataRow.forEach((row) => {
      switch (row.chartType) {
        case 'InvertBarChart':
          balance = Math.max(balance, row.selectedChartData?.labels?.length || 0);
          break;
        case 'BarChart':
          balance = Math.max(balance, 4);
          break;
      }
    });

    if (balance) {
      return dataRow.map((row) => {
        switch (row.chartType) {
          case 'InvertBarChart':
            if (row.selectedChartData?.labels) {
              while (row.selectedChartData.labels.length < balance) {
                row.selectedChartData.labels.push('');
              }
            }
            break;
        }
        row.section.balanceHeight = balance;
        return row;
      });
    } else {
      return dataRow;
    }
  },
  hasDateKeys: (data) => {
    let keys = Object.keys(data);
    let regex = new RegExp('^\\d{4}\\-\\d{2}(\\-\\d{2})?$');
    return regex.test(keys[0]);
  },
  getReportChartColors: (key) => {
    switch (key) {
      default:
        return { borderWidth: 3, borderColor: 'rgb(0, 123, 150)', backgroundColor: 'rgba(0, 123, 150, 0.5)' };
        break;
    }
  },
  getDataSummary: (reportKey, data, t) => {
    switch (reportKey) {
      case 'referrals_by_month':
        if (data) {
          return t('Showing data for x referrals across y clients', { referrals: data.referrals.length, clients: data.clients.length });
        }
        return false;
        break;
      case 'referral_statuses':
        let percentOpen = 0,
          percentClosed = 0;
        if (data) {
          if (data.open > 0 || data.closed > 0) {
            percentOpen = parseInt((data.open / (data.open + data.closed)) * 100);
            percentClosed = parseInt((data.closed / (data.open + data.closed)) * 100);
          }
          return t('Referrals open: O%, Referrals closed: C%', { open: percentOpen, closed: percentClosed });
        }
        return false;
        break;
      case 'time_to_close':
        let percentUtilized = 0;
        if (data) {
          if (data.total > 0) {
            percentUtilized = parseInt((data.successful / data.total) * 100);
          }
          return t('Utilization: U%', { utilization: percentUtilized });
        }
        return false;
        break;
    }
  },
  splitLongTitles: (title, length, maxLines = 2, allowedOverflow = 5) => {
    let titleParts = title?.split ? title.split(' ') : [''];
    let toJoin = [];
    let toJoinLength = 0;
    let finalTitle = [];

    titleParts.forEach((part, index) => {
      if (toJoinLength + part.length + 1 > length + allowedOverflow) {
        if (finalTitle.length == maxLines - 1) {
          toJoin.push('...');
        }
        finalTitle.push(toJoin.join(' '));
        toJoin = [];
        toJoinLength = 0;
      }
      if (maxLines !== false && finalTitle.length == maxLines) return;

      toJoin.push(part);
      toJoinLength += part.length + 1;
    });

    if (toJoinLength) {
      finalTitle.push(toJoin.join(' '));
    }

    return finalTitle;
  }
};

export default reportHelpers;

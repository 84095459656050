import { isPresent } from '@/utils/helpers';
import moment from 'moment';
import AgencyConfiguration from '@/utils/api/odas/agency_configuration';
import { localStorageService } from '@/utils/local_storage';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import isEmpty from 'lodash/isEmpty';
import t from '@/utils/Language/UI-translations';

/**
 * This is ported from the angular ResourceHelpers constant.
 */

const resourceHelpers = {
  emptyBadInfoReport: function (report) {
    let empty = true;
    for (let key in report) {
      if (key !== 'fetchable_type' && key !== 'fetchable_id') {
        empty = empty && (report[key] == false || report[key] == '');
      }
    }
    return empty;
  },

  emptyFlag: function (flags) {
    let notEmpty = false;
    flags.forEach((value, key) => {
      notEmpty = notEmpty || (value && value != '');
    });
    return !notEmpty;
  },

  emptyOfficeHours: function (officeHours) {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    if (officeHours === null || officeHours === undefined) {
      return true;
    }

    let empty = true;

    for (let day in days) {
      empty = empty && (officeHours[days[day] + '_start'] === '' || officeHours[days[day] + '_start'] == null);
    }

    return empty;
  },

  emptySchedule: function (schedule) {
    let allEmpty = true;
    if (schedule) {
      Object.entries(schedule).forEach(([key, value]) => {
        if (key != 'translations') {
          allEmpty = allEmpty && (value == '' || value == null);
        }
      });
    }

    return allEmpty;
  },

  /**
   * Get information about the next day/time this resource's primary schedule
   * is going to be open. This assumes that the user's local timezone is the same
   * as the location's timezone.
   *
   * @returns null if no scheduled times are set, otherwise an object with:
   *   - `now` boolean: whether it is open now
   *   - `day` string: lowercase name of the next open day
   *   - `start` string: value of the start time for next open day (assumes hh:mm)
   *   - `end` string: value of the end time for next open day (assumes hh:mm)
   */
  primaryNextOpen: function (resource, DateHelpers) {
    let schedule = this.primarySchedule(resource);
    if (!schedule) return null;

    const enrich = function (data) {
      data.dayAbbrev = DateHelpers.getAbbreviationForDayName(data.day);
      data.startTime = DateHelpers.formatTime(data.start);
      data.endTime = DateHelpers.formatTime(data.end);
      return data;
    };

    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    let today = new Date().getDay();
    let start = schedule[days[today] + '_start'];
    let end = schedule[days[today] + '_end'];

    // check that start and end times exist, and the current time is somewhere in between
    if (start && end && moment(start, 'hh:mm').diff() < 0 && moment(end, 'hh:mm').diff() > 0) {
      return enrich({ now: true, day: days[today], start: start, end: end });
    }

    // look for any subsequent day that has start and end times
    for (let i = 1; i < days.length; i++) {
      let d = (i + today) % days.length;
      let start = schedule[days[d] + '_start'];
      let end = schedule[days[d] + '_end'];
      if (start && end) {
        return enrich({ now: false, day: days[d], start: start, end: end });
      }
    }

    return null;
  },

  primaryLocation: function (resource) {
    if (resource.locations == undefined || (resource.locations && resource.locations?.length == 0)) {
      return null;
    }

    let primary = null;
    resource.locations?.forEach((location) => {
      if (location.is_primary) {
        primary = location;
      }
    });
    return primary == null ? resource.locations[0] : primary;
  },

  primarySchedule: function (resource) {
    if (resource?.schedule && this.emptySchedule(resource.schedule)) {
      if (resource.locations?.length > 0) {
        if (this.emptySchedule(this.primaryLocation(resource).schedule)) {
          return null;
        } else {
          return this.primaryLocation(resource).schedule;
        }
      } else {
        return null;
      }
    } else {
      return resource?.schedule;
    }
  },

  primaryPhone: function (resource) {
    let primary = null;
    resource.phones.forEach((phone) => {
      if (phone.is_primary) {
        primary = phone;
      }
    });
    return primary == null ? resource.phones[0] : primary;
  },

  generateSchedule: (schedule, hideClosedDays, abbreviateDays) => {
    const last = (array) => array[array.length - 1];

    const formatDay = function (dayIndex, abbreviateDays) {
      if (abbreviateDays) {
        let ret = capitalize(moment.weekdaysShort(dayIndex + 1)); // moment starts with sunday

        // moment inconsistently adds a trailing period to the abbrevation - remove it
        if (ret.slice(-1) == '.') {
          ret = ret.slice(0, -1);
        }
        return ret;
      } else {
        return capitalize(moment.weekdays(dayIndex + 1));
      }
    };

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const all_closed = (schedule) => resourceHelpers.emptyOfficeHours(schedule);

    const hours = (day, schedule) => {
      if (!(schedule[day + '_start'] && schedule[day + '_end'])) {
        if (hideClosedDays) {
          return null;
        } else {
          return t('Closed');
        }
      } else {
        let day_hours = normalizedHours(schedule[day + '_start']);
        day_hours += ' - ';
        day_hours += normalizedHours(schedule[day + '_end']);
        return day_hours;
      }
    };

    const normalizedHours = (hours) => {
      if (!(hours instanceof Date)) {
        if (hours == null) {
          return null;
        }
        hours = hours.length < 5 ? '0' + hours : hours;
        let hours_part = parseInt(hours.substring(0, 2));
        let minutes_part = hours.substring(3, 5);
        let am_pm = hours_part > 11 ? 'pm' : 'am';
        if (hours_part > 12) {
          hours_part = hours_part - 12;
        } else if (hours_part == 0) {
          hours_part = 12;
        }
        minutes_part = minutes_part === '00' ? '' : ':' + minutes_part;
        return hours_part + minutes_part + ' ' + am_pm;
      }
    };

    if (!schedule) {
      return null;
    }

    if (all_closed(schedule)) {
      if (schedule['notes']) {
        return schedule['notes'];
      }
      return null;
    }

    let day_hour_groupings = [];
    for (let i = 0; i < days.length; i++) {
      let this_days_hours = hours(days[i], schedule);
      if (this_days_hours) {
        let index_of_last_grouping = day_hour_groupings.length - 1;
        let start_day_of_last_grouping, end_day_of_last_grouping;
        let hours_of_last_grouping;
        if (day_hour_groupings[day_hour_groupings.length - 1] !== undefined) {
          start_day_of_last_grouping = last(day_hour_groupings)[0];
          end_day_of_last_grouping = last(day_hour_groupings)[1];
          hours_of_last_grouping = last(day_hour_groupings)[2];
        }
        if (i == 0 || hours_of_last_grouping !== this_days_hours || end_day_of_last_grouping !== i - 1) {
          day_hour_groupings.push([i, i, this_days_hours]);
        } else {
          day_hour_groupings[index_of_last_grouping] = [start_day_of_last_grouping, i, this_days_hours];
        }
      }
    }

    return (
      <table className="schedule-table">
        <tbody>
          {day_hour_groupings.map((day_hour_grouping, index) => (
            <tr key={index}>
              <th>
                {day_hour_grouping[0] !== day_hour_grouping[1]
                  ? formatDay(day_hour_grouping[0], abbreviateDays) + ' - ' + formatDay(day_hour_grouping[1], abbreviateDays)
                  : formatDay(day_hour_grouping[0], abbreviateDays)}
              </th>
              <td>{day_hour_grouping[2]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  },

  determineOrganizationName: (resource) => {
    switch (resource.resource_type) {
      case 'Collection':
        return resource.owner_organization?.name || '';
        break;
      case 'Organization':
        return resource.name;
        break;
      default:
        return resource.organization?.name || '';
        break;
    }
  },

  titleOf: (resource) => {
    if (resource.resource_type == 'TopicPage') {
      if (resource.community !== resource.title && resource.tag !== resource.title) {
        return resource.title;
      } else if (resource.community) {
        return 'community-' + resource.community;
      } else if (resource.tag) {
        return resource.tag;
      }
    } else {
      return resource[resourceHelpers.nameField(resource.resource_type)];
    }
  },

  nameField: (resourceType) => {
    switch (resourceType) {
      case 'Organization':
        return 'name';
        break;
      default:
        return 'title';
        break;
    }
  },

  pathTo: (resource) => {
    let pathPrefix = '/';
    switch (resource?.resource_type) {
      case 'Opportunity':
        pathPrefix = pathPrefix + 'opp/';
        break;
      case 'Organization':
        pathPrefix = pathPrefix + 'org/';
        break;
      case 'Collection':
        pathPrefix = pathPrefix + 'collection/';
        break;
      case 'Assessment':
        pathPrefix = pathPrefix + 'assessments/';
        break;
      default:
        break;
    }

    return pathPrefix + resource?.slug;
  },

  collectionName: function (type) {
    switch (type) {
      case 'Opportunity':
        return 'opportunities';
        break;
      case 'Organization':
        return 'organizations';
        break;
      case 'HousingProperty':
        return 'housing_properties';
        break;
      case 'Collection':
        return 'collections';
        break;
      case 'TopicPage':
        return 'topic_pages';
        break;
    }
  },

  goToEditPage: function ($location) {
    $location.url($location.$$path + '/edit');
  },

  goToAddOppPage: function ($location) {
    $location.url($location.$$path + '/opp/new');
  },

  goToAddOrgPage: function ($location) {
    $location.url('/org/new');
  },

  resourceTypeFromCollection: function (collection) {
    switch (collection) {
      case 'opportunities':
        return 'Opportunity';
        break;
      case 'organizations':
        return 'Organization';
        break;
      case 'collections':
        return 'Collection';
        break;
    }
  },

  sortPropertyObjectAlphabetically: function (object) {
    let sortableObject = map(object, function (data, name) {
      return { name: name, value: data };
    });

    // To ensure eligibility requirements are first on the resource card, we have to separate those values out
    // Move props not related to eligibility into a new hash and sort them alphabetically
    let nonEligObj = [];
    let eligObj = [];
    sortableObject.filter(function (obj) {
      if (obj.name.indexOf('elig-') > -1) {
        eligObj.push(obj);
      } else {
        nonEligObj.push(obj);
      }
    });
    nonEligObj = sortBy(nonEligObj, function (o) {
      return o.name;
    });
    eligObj = sortBy(eligObj, function (o) {
      return o.name;
    });
    // Combine and return the sorted sets
    return union(eligObj, nonEligObj);
  },

  pluralizeResourceCollection: function (count, collectionName) {
    return count === 1 ? this.resourceTypeFromCollection(collectionName).toLowerCase() : collectionName;
  },

  getFetchableIds: function (fetchables) {
    if (!fetchables) {
      return {};
    }
    let that = this;
    let ids = { opportunities: [], organizations: [], housing_properties: [], collections: [], topic_pages: [] };
    fetchables.forEach((fetchable) => {
      let collection = that.collectionName(fetchable.fetchable_type);
      ids[collection].push(fetchable.fetchable_id.toString());
    });
    return ids;
  },

  imageUrl: function (resource) {
    if (resource.resource_type === 'Collection') {
      return resource.image_url;
    } else {
      return null;
    }
  },

  isCurrentlyUnavailable: function (resource) {
    const unavailableDate = Date.parse(resource?.unavailable_at);
    return !isNaN(unavailableDate) && unavailableDate < Date.now();
  },

  buildDirectionsUrl: function (location) {
    if (location == null) {
      return '';
    }
    let url = location.address + ' ' + location.city + ' ' + location.state;
    url += location.zip_code ? ' ' + location.zip_code : '';
    url = url.replace(/ /g, '+');
    return url;
  },

  // layout options are "block" (multi-line), "compact" (two lines), and "inline" (one line)
  generateAddressBlock: ({ location, includeName, makeDirectionLink, layout = 'block', distanceTo, ...options }) => {
    const theAddress = () => {
      let addressLine1 = [];
      let addressLine2 = [];
      let addressLine3 = [];
      if (isPresent(location.name) && includeName) {
        addressLine1.push(location.name);
      }
      if (isPresent(location.address)) {
        addressLine1.push(location.address);
      }
      if (isPresent(location.unit)) {
        addressLine2.push(location.unit);
      }

      addressLine3.push(location.city + ', ' + location.state + (isPresent(location.zip_code) ? ' ' + location.zip_code : ''));
      let fullAddress = addressLine1.concat(addressLine2).concat(addressLine3);
      return (
        <>
          {layout === 'block' && (
            <>
              {fullAddress.map((line, index) => (
                <>
                  {line} {index + 1 < fullAddress.length ? <br /> : null}
                </>
              ))}
            </>
          )}
          {layout === 'compact' && (
            <>
              {addressLine1.concat(addressLine2).join(', ')}
              <br />
              {addressLine3.join(', ')}
            </>
          )}
          {layout === 'inline' && <>{fullAddress.join(', ')}</>}
          <span></span>
        </>
      );
    };
    return (
      <address lat={location.lat} long={location.long}>
        {makeDirectionLink ? (
          <a href={'https://maps.google.com?daddr=' + resourceHelpers.buildDirectionsUrl(location)} target="_blank" rel="noreferrer">
            {theAddress()}
            {distanceTo}
          </a>
        ) : (
          <span>
            {theAddress()}
            {distanceTo}
          </span>
        )}
      </address>
    );
  },

  generateInlineAddressBlock: ({ location, includeName, makeDirectionLink, makeTwoLines }, ...options) => {
    const theAddress = () => {
      let addressLine1 = [];
      let addressLine2 = [];
      if (isPresent(location.name) && includeName) {
        addressLine1.push(location.name);
      }
      if (isPresent(location.address)) {
        addressLine1.push(location.address);
      }
      if (isPresent(location.unit)) {
        addressLine1.push(location.unit);
      }

      addressLine2.push(location.city + ', ' + location.state + (isPresent(location.zip_code) ? ' ' + location.zip_code : ''));

      return (
        <>
          {makeTwoLines ? (
            <>
              {addressLine1.join(', ')}
              <br />
              {addressLine2.join(', ')}
            </>
          ) : (
            <>{addressLine1.concat(addressLine2).join(', ')}</>
          )}
          <span></span>
        </>
      );
    };

    return (
      <address lat={location.lat} long={location.long}>
        {makeDirectionLink ? (
          <a href={'https://maps.google.com?daddr=' + resourceHelpers.buildDirectionsUrl(location)} target="_blank" rel="noreferrer">
            {theAddress()}
          </a>
        ) : (
          <span>{theAddress()}</span>
        )}
      </address>
    );
  },

  propertyLabel: function (value) {
    switch (value.toUpperCase()) {
      case 'TRUE':
        return 'Yes';
        break;
      case 'FALSE':
        return 'No';
        break;
      default:
        return value;
        break;
    }
  },

  propertyRange: (obj) => {
    return (
      (obj.over ? obj.over.toString() : '') +
      (!obj.under ? '+' : '') +
      (obj.over && obj.under ? '&nbsp;&ndash;&nbsp;' : '') +
      (!obj.over ? '&lt;' : '') +
      (obj.under ? obj.under.toString() : '')
    );
  },

  deleteInfoModalMessage: function (resourceType, editPage) {
    if (resourceType == 'Organization' && editPage) {
      return 'Are you sure you want to delete this organization? <br/> This action cannot be undone.';
    } else if (resourceType == 'Organization') {
      return 'Are you sure you want to mark this organization for deletion?';
    } else {
      return 'Are you sure you want to mark this opportunity for deletion?';
    }
  },

  addProperty: function (key, value) {
    this.props[key] = value;
  },

  addListProperty: function (key, property, addToFront) {
    this.props[key] = this.props[key] || [];
    if (this.props[key].indexOf(property) > -1) {
      return;
    }
    if (addToFront || property.match(/-available-everyone/)) {
      this.props[key].unshift(property);
    } else {
      this.props[key].push(property);
    }
  },

  addListPropertyWithDescription: function (key, name, value) {
    if (name.match(/\-description|-note/)) {
      this.addListProperty(key, value);
    } else if (this.isBoolValue(value)) {
      this.addListProperty(key, name, true);
    } else {
      this.addListProperty(key, value);
    }
  },

  addEligRangeProperty: function (categoryText, minVal, maxVal) {
    this.props['eligibility-requirements'] = this.props['eligibility-requirements'] || [];
    this.props['eligibility-requirements'].push(
      categoryText + (categoryText === '[income-elig]' ? '$' : '') + minVal + ' – ' + (categoryText === '[income-elig]' ? '$' : '') + maxVal
    );
  },

  isBoolValue: function (value) {
    return typeof value === 'string' && (value.toUpperCase() == 'TRUE' || value.match(/yes|si/i)) ? true : false;
  },

  listedProperties: function (properties, SearchHelpers) {
    this.props = {};
    let sortedProperties = this.sortPropertyObjectAlphabetically(properties);
    let ageObj = [];
    // Ranges for Eligibility
    let ageRange = {};
    let gradeRange = {};
    let householdRange = {};
    let incomeRange = {};
    let objRanges = [
      {
        objName: ageRange,
        categoryText: '[age-elig]',
        minVal: 'elig-age-or-over',
        maxVal: 'elig-age-or-under'
      },
      {
        objName: gradeRange,
        categoryText: '[grade-elig]',
        minVal: 'elig-grade-or-over',
        maxVal: 'elig-grade-or-under'
      },
      {
        objName: householdRange,
        categoryText: '[child-elig]',
        minVal: 'elig-household-over18-count',
        maxVal: 'elig-household-under18-count'
      },
      {
        objName: incomeRange,
        categoryText: '[income-elig]',
        minVal: 'elig-annual-income-or-over',
        maxVal: 'elig-annual-income-or-under'
      }
    ];

    each(
      sortedProperties,
      (property) => {
        let value = property.value;
        let name = property.name;
        let boolValue = this.isBoolValue(value);

        if (name.match(/^lang\-/)) {
          if (boolValue) {
            this.addListProperty('languages', name);
          }
        } else if (name.includes('elig-documentation')) {
          this.addListProperty('elig-documentation', value);
        } else if (name.match(/^citizenship\-/)) {
          this.addListPropertyWithDescription('citizenship-requirements', name, value);
        } else if (name.match(/^safespaces\-/)) {
          if (boolValue) {
            this.addListProperty('safespaces', name);
          }
        } else if (name == 'earn-industry-cert-description') {
          this.addProperty('earn-cert', value);
        } else if (name == 'earn-college-credits-description') {
          this.addProperty('earn-college', value);
        } else if (name.match(/^coordinated-entry-site/)) {
          if (value && value.toLowerCase() == 'true') {
            this.addListProperty('coordinated-entry-site', 'Yes');
          } else {
            this.addListProperty('coordinated-entry-site', value);
          }
        } else if (name.match(/^elig\-/)) {
          if (name == 'elig-available-everyone') {
            if (boolValue) {
              this.addProperty(name, 'none');
            }
          } else if (SearchHelpers.ageOptions.indexOf(name) > -1 || name.match(/-age-/)) {
            if (SearchHelpers.ageOptions.indexOf(name) > -1) {
              if (boolValue) {
                ageObj.push(name);
              }
            } else if (name.match(/-age-/)) {
              ageRange[name] = value;
            }
          } else if (name == 'elig-county-resident') {
            if (value.toLowerCase().includes('county') || value.toLowerCase().includes('counties')) {
              this.addListProperty('eligibility-requirements', '[resident-of]' + value);
            } else {
              this.addListProperty('eligibility-requirements', '[resident-of]' + value + ' County');
            }
          } else if (name == 'elig-city-resident') {
            this.addListProperty('eligibility-requirements', '[resident-of]' + value);
          } else if (name.match(/^elig-city/)) {
            // Skipping if elig-city but not elig-city-resident
          } else if (name.match(/^elig-grade/)) {
            gradeRange[name] = value;
          } else if (name.match(/^elig-household-.*-count/)) {
            householdRange[name] = value;
          } else if (name.includes('elig-annual-')) {
            incomeRange[name] = value;
          } else {
            this.addListPropertyWithDescription('eligibility-requirements', name, value);
          }
        } else if (name == 'time-appointment-required' && boolValue) {
          this.addProperty('time-appointment-required', 'TRUE');
        } else if (name == 'cost-free' && boolValue) {
          this.addProperty('cost-free', 'TRUE');
        } else if (name.match(/^cost-/) && value != '' && value != null) {
          this.addProperty(name, value);
        } else if (name.match(/^ada\-(access)/) || name == 'accepts-medicaid') {
          this.addProperty(name, value);
        } else if (name.match(/^fin-aid-|accepts-medicaid/) && value != '' && value != null) {
          this.addListPropertyWithDescription('fin-aid', name, value);
        }
      },
      this
    );

    // Add Age to Eligibility
    if (ageObj.length > 0) {
      ageObj.forEach((ageGroup) => {
        this.addListProperty('eligibility-requirements', ageGroup);
      });
    }
    // Add Ranges to Eligibility
    objRanges.forEach((range) => {
      if (Object.keys(range['objName']).length == 2) {
        this.addEligRangeProperty(range['categoryText'], range['objName'][range['minVal']], range['objName'][range['maxVal']]);
      } else if (Object.keys(range['objName']).length == 1) {
        if (range['objName'][range['minVal']]) {
          this.addListProperty(
            'eligibility-requirements',
            range['categoryText'] + (range['categoryText'] === '[income-elig]' ? '$' : '') + range['objName'][range['minVal']] + '+'
          );
        } else {
          this.addListProperty(
            'eligibility-requirements',
            range['categoryText'] + '<\xa0' + (range['categoryText'] === '[income-elig]' ? '$' : '') + range['objName'][range['maxVal']]
          );
        }
      }
    });
    return this.props;
  },

  mixpanelEventHash: (resource) => {
    let mixpanelHash = {};
    mixpanelHash['resource_type'] = resource.resource_type;
    if (resource.resource_type == 'Organization') {
      mixpanelHash['organization_id'] = resource.id;
    } else {
      mixpanelHash['opportunity_id'] = resource.id;
      mixpanelHash['organization_id'] = resource.organization.id;
    }
    return mixpanelHash;
  },

  replaceWithTranslations: function (obj) {
    let that = this;
    if (obj && !isEmpty(obj.translations)) {
      each(obj.translations, (value, key) => {
        obj[key] = obj.translations[key];
      });
    }

    each(obj, (value, key) => {
      if (value !== null && typeof value === 'object' && value.translations) {
        obj[key] = that.replaceWithTranslations(value);
      }

      if (Array.isArray(value) && value.length > 0) {
        obj[key] = that.replaceAllWithTranslations(value);
      }
    });

    return obj;
  },

  replaceAllWithTranslations: function (objs) {
    if (objs) {
      for (let i = 0; i < objs.length; i++) {
        objs[i] = this.replaceWithTranslations(objs[i]);
      }
    }
    return objs;
  },

  emailMeta: function (email) {
    if (!email) {
      return '';
    }

    let name = null;

    if (email.first_name && email.last_name) {
      name = email.first_name + ' ' + email.last_name;
    } else {
      name = email.first_name || email.last_name || null;
    }

    if (name && email.title) {
      return name + ', ' + email.title;
    } else {
      return name || email.title || '';
    }
  },

  getServiceAreaType: function (property, pluralize) {
    let areaType = property.split('-')[1];
    if (pluralize) {
      switch (areaType) {
        case 'county':
          areaType = 'Counties';
          break;
        case 'borough':
          areaType = 'Boroughs';
          break;
        case 'parish':
          areaType = 'Parishes';
          break;
      }
    }
    return areaType;
  },

  formatPhoneNumber: function (phoneNumber) {
    return phoneNumber ? phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : phoneNumber;
  },

  convertToCallablePhoneNumber: function (phoneNumber) {
    phoneNumber = phoneNumber.match(/[0-9]/g).join('');
    if (phoneNumber[0] == '1') {
      return phoneNumber.substring(0, 11);
    } else {
      return phoneNumber.substring(0, 10);
    }
  },
  /* In Angular, this is in the AgencyConfiguration service but it seemed to fit better in the resource helpers */
  checkResourceNetworkStatus: (resource) => {
    // TODO sync this with bootstrapped CLR data (ticket 1deg/application-server/7922)
    return null;
  }
};

export default resourceHelpers;

/**
 * This is a partial port of the angular Location service with noted adapations to work in a React context.
 * The most notable adaption is that all storage and management of the actual current Location/Region
 * is handled by the location.context.js rather than in this helper file.
 */

import Geocode from 'react-geocode';
import axios from 'axios';
import ODAS from '@/utils/api/odas';
import regionsObject from '@/rails/config/properties/regions.yml';
import statesObject from '@/rails/config/static/states.yml';
import { isBot } from '@/utils/utils';
import Keys from '@/constants/Keys';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';
import find from 'lodash/find';

Geocode.setApiKey(process.env.ODAS_GEOCODER_API_KEY);

const DEFAULT_REGION = 'sf';
const REGION_RADIUS = 100; // miles
const METERS_PER_MILE = 1609.344; // meters
const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour

let geolocationPermissionGranted = null;
let geolocationPermissionCheckPromise = null;

// Including the lat/lng as their long name to keep compatibility with how Angular adds them to localStorage.
// namedLocations was previously an array of arrays in Angular with set indicies for each datapoint -
// this has been upgraded to an array of objects for React for ease of use and clearer code.
// Additionally the locationName key is used rather than name.
const namedLocations = mapValues(regionsObject, (region, regionCode) => {
  region.latitude = region.lat;
  region.longitude = region.lng;
  region.locationName = region.name;
  return region;
});

const states = Object.keys(statesObject)
  .sort()
  .map((abbrv) => ({ state: statesObject[abbrv], stateKey: abbrv }));

const supportedRegions = Object.keys(namedLocations)
  .filter((locationId) => namedLocations[locationId]?.main)
  .map((locationId) => ({ id: locationId, ...namedLocations[locationId] }));

/**
 * Extract a particular component from a Google Maps place by passing in the place, the Google
 * name of the component you're looking for, and a boolean indicating whether you want the short or
 * long name. For example, to extract the long state name, use
 * getComponentFromPlace(place, 'administrative_area_level_1', true).
 */
const getComponentFromPlace = (place, componentType, getLong) => {
  if (place.address_components) {
    for (var i = 0; i < place.address_components.length; i++) {
      var component = place.address_components[i];
      if (component.types) {
        for (var j = 0; j < component.types.length; j++) {
          if (component.types[j] === componentType) {
            return getLong ? component.long_name : component.short_name;
          }
        }
      }
    }
  }
  return null;
};

const detectLocation = (options = {}) => {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        geolocationPermissionGranted = true;
        resolve(position);
      },
      (error) => {
        reject(error);
      },
      {
        timeout: options.timeout ? options.timeout : 10000
      }
    );
  });
};

const closestRegion = (latLng, regionRadius, defaultRegion, regionList) => {
  let selectedRegion = defaultRegion;
  let regionFound = false;
  Object.keys(namedLocations).forEach((key) => {
    if (Array.isArray(regionList) && !regionList.includes(key)) return;
    let distanceThisLoc = google.maps.geometry.spherical.computeDistanceBetween(
      latLng,
      new google.maps.LatLng(namedLocations[key].lat, namedLocations[key].lng)
    );
    if (distanceThisLoc <= regionRadius * METERS_PER_MILE && !regionFound) {
      selectedRegion = key;
      regionFound = true;
    }
  });
  return selectedRegion;
};

const guessClosestRegion = (region_radius, default_region) => {
  return new Promise((resolve, reject) => {
    const detectLocationFromIp = () => {
      axios
        .get(Keys.googleCloud.geolocationUrl)
        .then((response) => {
          if (response) {
            let currentLatLng = new google.maps.LatLng(
              response.cityLatLong.slice(0, response.cityLatLong.indexOf(',')),
              response.cityLatLong.slice(response.cityLatLong.indexOf(',') + 1)
            );
            resolve(closestRegion(currentLatLng, region_radius, default_region));
          }
          resolve(default_region);
        })
        .catch((err) => {
          reject(err);
        });
    };

    if (isBot()) {
      reject({ message: 'bot detected' });
    }

    // These paths are not relevant to React (yet) but were part of this function that was ported directly from Angular
    if (window.location.pathname === '/nyc') {
      resolve('nyc');
    }
    if (window.location.pathname === '/swnm') {
      resolve('swnm');
    }
    if (window.location.pathname === '/live6') {
      resolve('detroit');
    }
    if (window.location.pathname === '/lacountyassist') {
      resolve('la');
    }

    locationHelpers.checkGeolocationPermissions().then((permissionGranted) => {
      if (permissionGranted) {
        detectLocation()
          .then((position) => {
            resolve(closestRegion(new google.maps.LatLng(position.coords.latitude, position.coords.longitude)), region_radius, default_region);
          })
          .catch(detectLocationFromIp);
      } else {
        detectLocationFromIp();
      }
    });
  });
};

const regionObjectFromId = (id) => {
  return find(supportedRegions, { id: id });
};

const countyFromPlace = (place) => {
  var county;
  var regionFromPlace = find(supportedRegions, (region) => {
    return region.name == place.formatted_address;
  });
  if (regionFromPlace) {
    county = regionFromPlace.county;
  } else {
    county = getComponentFromPlace(place, 'administrative_area_level_2', true) || '';
  }
  return county.replace('County', '').trim();
};

const locationHelpers = {
  cacheExpiration: CACHE_EXPIRATION,
  detectedRegion: () => {
    return guessClosestRegion(REGION_RADIUS, DEFAULT_REGION);
  },

  detectLocation: detectLocation,

  /**
   * Get the county name and chop off "County".
   * For some of our supported cities (NYC), Google does not return a county name
   * so first, try to find the county from supportedRegions
   * otherwise, try to get the County from the Google place object
   */
  getCountyFromPlace: (place) => {
    return countyFromPlace(place);
  },

  getCurrentRegion: () => {
    return new Promise((resolve, reject) => {
      locationHelpers
        .detectedRegion()
        .then((regionId) => {
          if (regionId) {
            resolve({ region: regionId, usingDefaultRegion: false });
          } else {
            throw new Error(); //trigger catch logic
          }
        })
        .catch((err) => {
          resolve({ region: DEFAULT_REGION, usingDefaultRegion: true });
        });
    });
  },

  getDetectedLocation: () => {
    return detectLocation().then((position) => {
      return locationHelpers.reverseGeocode(position.coords.latitude, position.coords.longitude);
    });
  },

  getNamedLocation: (locationId) => {
    return namedLocations[locationId];
  },

  getClosestRegion: closestRegion,

  // determine default location for when there wasn't a location question in the assessment
  // if assessment is regional, in and currenty location isn't close to one of the supported regions,
  // set the default region to the center of the first enabled region
  determineAssessmentDefaultLocation: (currentLocation, assessmentRegionList) => {
    try {
      if (!assessmentRegionList || locationHelpers.isLatLngInRegion(currentLocation, assessmentRegionList)) {
        return currentLocation;
      } else {
        return locationHelpers.getRegionObjectFromId(
          locationHelpers.getClosestRegion(currentLocation, 1000, assessmentRegionList[0], assessmentRegionList)
        );
      }
    } catch (error) {
      return locationHelpers.getRegionObjectFromId('sf');
    }
  },

  getRegionByLatLng: (lat, lng) => {
    return ODAS.get('/api/closest/region', {
      lat: lat,
      lng: lng
    })
      .then((response) => {
        if (response.status === 200) {
          return { region: response.data.region, usingDefaultRegion: response.data.is_default };
        } else {
          throw new Error(); //trigger catch logic
        }
      })
      .catch((error) => {
        return { region: DEFAULT_REGION, usingDefaultRegion: true };
      });
  },

  getRegionObjectFromId: (region) => {
    return regionObjectFromId(region);
  },

  /**
   * Get the long state name (eg "California") from a Google Maps place.
   */
  getStateFromPlace: (place, longName = true) => {
    return getComponentFromPlace(place, 'administrative_area_level_1', longName);
  },

  isLatLngInRegion: (latLng, regionId) => {
    if (namedLocations[regionId]?.bounding_box?.length === 2) {
      return (
        latLng.lat < namedLocations[regionId]?.bounding_box[0].lat &&
        latLng.lat > namedLocations[regionId]?.bounding_box[1].lat &&
        latLng.lng > namedLocations[regionId]?.bounding_box[0].lng &&
        latLng.lng < namedLocations[regionId]?.bounding_box[1].lng
      );
    } else {
      return false;
    }
  },

  stateAbbreviationToName: (abbrv) => {
    if (typeof statesObject[abbrv.toUpperCase()] !== 'undefined') {
      return statesObject[abbrv.toUpperCase()];
    } else {
      return abbrv;
    }
  },

  getZipFromLatLngs: (lat, lng) => {
    return Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const zip = response.results[0].address_components.filter((c) => c.types.includes('postal_code'))?.[0]?.short_name;
        return zip;
      },
      (error) => {
        console.error(error);
      }
    );
  },

  getZipFromPlace: (place) => {
    return getComponentFromPlace(place, 'postal_code');
  },

  getLocationFromZipCode: (zip) => {
    // Google Maps Geocode doesn't return results if only a zip code is passed, append
    // the country to the string to receive results.
    // Seems safe to hard code this since to serve other countries, we'd need a lot of
    // code updates - adding a TODO below to help locate this if/when we consider supporting
    // another country.
    // TODO: International Support
    return locationHelpers.getLocationFromAddressString(zip + ', US');
  },

  getLocationFromAddressString: (address) => {
    return Geocode.fromAddress(address).then((response) => {
      if (response.results && response.results.length) {
        return locationHelpers.buildLocationFromPlace(response.results[0]);
      } else {
        throw new Error(`No results returned for getLocationFromAddressString("${address}")`);
      }
    });
  },

  checkGeolocationPermissions: () => {
    if (geolocationPermissionGranted !== null) {
      return new Promise((resolve) => {
        resolve(geolocationPermissionGranted);
      });
    } else if (geolocationPermissionCheckPromise !== null) {
      return geolocationPermissionCheckPromise;
    } else if (navigator.permissions && navigator.permissions.query) {
      geolocationPermissionCheckPromise = navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          geolocationPermissionGranted = result && result.state === 'granted';
          return geolocationPermissionGranted;
        })
        .catch((error) => {
          console.error(error);
          geolocationPermissionGranted = false;
          return geolocationPermissionGranted;
        });
      return geolocationPermissionCheckPromise;
    } else {
      geolocationPermissionGranted = false;
      return new Promise((resolve) => {
        resolve(geolocationPermissionGranted);
      });
    }
  },

  isGeolocationPermissionGranted: () => {
    return geolocationPermissionGranted;
  },

  reverseGeocode: (lat, lng) => {
    return Geocode.fromLatLng(lat, lng)
      .then((response) => {
        if (response.results && response.results.length) {
          return Object.assign(response.results[0], {
            lat: lat,
            lng: lng,
            latitude: lat,
            longitude: lng,
            locationName: response.results[0].formatted_address,
            state: locationHelpers.getStateFromPlace(response.results[0]),
            county: locationHelpers.getCountyFromPlace(response.results[0]),
            cachedAt: Date.now()
          });
        } else {
          throw new Error(`No results returned for ${lat}, ${lng}`);
        }
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  },
  getSupportedRegionsOptionsList: () => {
    const regions = each(supportedRegions, (region) => {
      const state_abbrv = region.name.split(', ')[1].slice(0, 2); // the first two characters here are the state abbreviation
      const region_name_short = region.region_name.split(',')[0]; // if the region name also contains the state, don't include it
      return (region.option_name = state_abbrv + ' - ' + region_name_short);
    });
    return sortBy(regions, 'option_name');
  },
  buildLocationFromPlace: (place) => {
    if (!place) return false;
    // If place comes from GooglePlace API (via Map or Autocomplete component), it has LatLng ({lat: fn(), lng: fun()}) interface for its geometry
    // However if place comes from Geocoder, it has LatLngLiteral ({lat: .., lng: ..}) interface for its geometry
    const lat = typeof place.geometry?.location?.lat == 'function' ? place.geometry.location.lat() : place.geometry?.location?.lat;
    const lng = typeof place.geometry?.location?.lat == 'function' ? place.geometry.location.lng() : place.geometry?.location?.lng;
    return {
      county: locationHelpers.getCountyFromPlace(place),
      latitude: lat,
      longitude: lng,
      lat: lat,
      lng: lng,
      locationName: place.formatted_address,
      state: locationHelpers.getStateFromPlace(place),
      cachedAt: Date.now()
    };
  },

  isCountyFromCategoryMappings: (categoryMappings, county) => {
    return categoryMappings[county?.toLowerCase()] !== undefined;
  }
};

export default locationHelpers;

import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';
import Language from '@/utils/Language';
import { LocationContext } from '@/context/location.context';
import Auth from '@/utils/api/odas/auth';
import { viewportContext } from '@/context/viewport.context';
import BotsList from '@/rails/config/static/bots.yml';
import { AccountContext } from '@/context/account.context';
import { useRouter } from 'next/router';
import { getCookie, setCookie } from '@/utils/cookies';

const DEBUG = typeof window !== 'undefined' && window.location.hostname === 'localhost';

const useMixpanel = (eventOrigin) => {
  const { unusedIsMobile } = useContext(viewportContext);
  const { location } = useContext(LocationContext);
  const [hasSetTracking, setHasSetTracking] = useState(false);
  const account = useContext(AccountContext);
  const router = useRouter();

  mixpanel.init(process.env.MIXPANEL_TOKEN);

  const tracking_origin_key = 'one-degree_origin';
  const env_check = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

  const track = (event, props) => {
    if (env_check && !isBot() && trackUser() && process.env.MIXPANEL_TOKEN) {
      augmentAndTrack(event, props);
    } else if (DEBUG) {
      console.info('** useMixPanel is ignoring', { event, props });
    }
  };

  const trackUser = () => {
    if (account?.state?.isLoggedIn) {
      return account.trackUser();
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!hasSetTracking) {
      if (router.query?.p) {
        setCookie(tracking_origin_key, router.query.p);
        setHasSetTracking(true);
      } else if (eventOrigin) {
        setCookie(tracking_origin_key, eventOrigin);
      }
    }
  }, []);

  useEffect(() => {
    if (hasSetTracking) {
      delete router.query?.p;
      router.push(router);
    }
  }, [hasSetTracking]);

  const augmentAndTrack = (event, props) => {
    const properties = props || {};
    const user = getCookie('user') || {};
    properties.origin = getCookie(tracking_origin_key);
    properties.app = 'one-degree';
    properties.unusedIsMobile = unusedIsMobile;
    properties.loggedIn = Auth.isLoggedIn();
    properties.locale = Language.getCurrentLanguage();
    properties.location = location;
    properties.userAgent = window.navigator.userAgent;

    if (typeof properties.location?.locationName === 'string') {
      properties.location = properties.location.locationName;
    } else if (typeof properties.location?.name === 'string') {
      properties.location = properties.location.name;
    }

    if (!isEmpty(user)) {
      properties.region = user.region;
      properties.pro = user.is_professional;
      properties.coordinates = user.latitude + ',' + user.longitude;
      properties.user_id_hash = user.id_hash;
      if (DEBUG) console.info('** mixpanel debugging for user', { event, properties });
      mixpanel.track(event, properties);
    } else {
      properties.region = location?.region;
      properties.coordinates = location?.lat + ',' + location?.lng;
      properties.user_id_hash = 'anonymous';
      if (DEBUG) console.info('** mixpanel debugging for anon', { event, properties });
      mixpanel.track(event, properties);
    }
  };

  const isBot = () => {
    return navigator.userAgent.toLowerCase().match(new RegExp(BotsList.join('|').toLowerCase()));
  };

  return {
    track: track
  };
};

export default useMixpanel;
